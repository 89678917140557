import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocaleData } from 'src/app/_datas/locale.data';
import { LOCALE_DYNAMIC_EN } from 'src/locale/dynamic/_index';
import { SeoService } from 'src/app/_services/seo.service';

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-content',
  standalone: true,
  imports: [CommonModule],
  providers: [SeoService],
  templateUrl: 'content.component.html',
  styleUrls: ['content.component.scss']
})
export class ContentComponent implements OnInit {
  private i18n_pageUrl: any = LocaleData.pageUrl();
  private i18n_pageTitle: any = LocaleData.pageTitle();
  private i18n_pageUrl_EN = LOCALE_DYNAMIC_EN.url;
  private i18n_pageDescription: any = LocaleData.pageDescription();
  private remoteUrl: string = "https://streetco-mobile-api.azurewebsites.net/content/";
  htmlContent: string = '';
  constructor(
    private seoService: SeoService,
    private http: HttpClient,
    private router: Router,
  ) { }
  ngOnInit(): void {
    this.init();
  }
  private init() {
    const routerUrl: string = this.router.url.substring(1);
    if (routerUrl == this.i18n_pageUrl.legal_notice) {
        this.setSeo(this.i18n_pageTitle.legal_notice, this.i18n_pageDescription.legal_notice);
        this.getContent(this.i18n_pageUrl_EN.legal_notice);
    } else if (routerUrl == this.i18n_pageUrl.privacy_policy) {
        this.setSeo(this.i18n_pageTitle.privacy_policy, this.i18n_pageDescription.privacy_policy);
        this.getContent(this.i18n_pageUrl_EN.privacy_policy);
    } else {
        //this.snackbarService.openErrorMessage();
        setTimeout(() => this.router.navigate(['/']), 2000);
    }
  }
  private getContent(uri: string): void {
    const headers = new HttpHeaders().set('Accept-Language', environment.locale);
    this.http.get(this.remoteUrl + uri, { headers: headers }).subscribe({
      next: (data: any) => {
        this.htmlContent = data.html;
      },
      error: (e) => {
        console.error(e);
        //this.snackbarService.openErrorMessage();
        setTimeout(() => this.router.navigate(['/']), 2000);
      }
    });
  }
  private setSeo(pageTitle: string, pageDescription: string): void {
    this.seoService.setPageTitle(pageTitle);
    this.seoService.setPageDescription(pageDescription);
    this.seoService.createLinkForCanonicalURL();
  }
}