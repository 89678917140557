export const SOCIALS_DATA: any = {
    nav: {
        google_play: 'https://play.google.com/store/apps/details?id=com.nvibe.streetnav',
        app_store: 'https://apps.apple.com/fr/app/streetnav/id6472163282',
    }, 
    co: {
        google_play: 'https://play.google.com/store/apps/details?id=io.streetco.shoot',
        app_store: 'https://apps.apple.com/fr/app/streetco/id1544239901',
    },
    facebook: 'https://www.facebook.com/StreetcoApp',
    twitter: 'https://twitter.com/streetcoapp',
    instagram: 'https://www.instagram.com/streetcoapp',
    youtube: 'https://www.youtube.com/channel/UCzfNkntiFnipua1m1Q-EHlA',
    linkedin: 'https://www.linkedin.com/company/pmr-street',
    hubspot_calendar: 'https://calendar.app.google/je6CiE88N4VoAReX6',
};

export const DATA_META_OPENGRAPH = {
    title: '',
    description: '',
    type: 'website',
    image: 'https://street-co.com/assets/img/meta/og.jpg',
    url: '',
    video: 'https://www.youtube.com/watch?v=if0lsFabNUQ',
    locale: '',
    site_name: 'Streetco'
};

export const DATA_META_TWITTER = {
    title: '',
    description: '',
    image: 'https://street-co.com/assets/img/meta/x.jpg',
    site: '@StreetcoApp',
    creator: '@StreetcoApp',
    card: 'summary'
};
