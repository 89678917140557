export const LOCALE_DYNAMIC_FR = {
    meta: {
        title: "Une mobilité accessible pour toutes et tous",
        social: {
            title: "Streetco : Des solutions pour une mobilité inclusive et accessible",
            description: "Bienvenue sur Streetco, la solution pour que tout le monde puisse bénéficier d’une mobilité piétonne simple, sécurisée et autonome",
        },
    },
    pages: {
        title: {
            landing: "Streetco : La solution pour une mobilité inclusive et accessible",
            app: "Les applications pour rendre le monde plus accessible",
            app_streetco: "Streetco : l’application pour avoir un impact sur l’accessibilité urbaine",
            app_streetnav: "Le GPS piéton pour toutes les mobilités réduites",
            offer: "Découvrez comment contribuer à un monde plus accessible",
            offer_challenge: "Challenge – Fédérez et sensibilisez vos collaborateurs.",
            offer_smartcity: "Smart City – Rendez votre ville accessible à tous.",
            mission: "Notre mission : rendre la mobilité accessible pour tous.",
            contact: "Contact - Streetco",
            legal_notice: "Mentions Légales - Streetco",
            privacy_policy: "Politique de confidentialité - Streetco",
            not_found: "Page non trouvée - Streetco",
            tmp_inclusion_cards: "Les cartes de l'inclusion, campagne lancée par Streetco",
        },
        description: {
            landing: "Bienvenue sur Streetco, la solution pour que tout le monde puisse bénéficier d’une mobilité piétonne simple, sécurisée et autonome.",
            app: "Nos deux applications Streetco et StreetNav proposent des solutions de collecte de données et de navigation en temps réel pour faciliter les déplacements des personnes à mobilité réduite.",
            app_streetco: "Renseignez les obstacles et identifiez les rues accessibles lors de vos déplacements du quotidien pour aider des millions de personnes à mieux se déplacer.",
            app_streetnav: "L’application mobile qui génère des itinéraires indoor, outdoor et multimodaux pour se déplacer sereinement et facilement quelle que soit sa mobilité.",
            offer: "Streetco propose plusieurs services pour répondre efficacement à vos besoins concernant l’accessibilité et le Handicap.",
            offer_challenge: "Un dispositif innovant, simple et ludique pour sensibiliser de façon concrète. Fédérez vos équipes autour d'une action qui a du sens.",
            offer_smartcity: "Chez Streetco, nous construisons un monde sans obstacles pour des villes plus accessibles. Aux plus fragiles et aux moins mobiles.",
            mission: " Chez Streetco, nous croyons en un futur où les villes sont accessibles à tous, où les déplacements des piétons sont confortables, sûrs et sans obstacles.",
            contact: "Une question ? Contactez-nous !",
            legal_notice: "Consultez nos mentions légales",
            privacy_policy: "Consultez notre politique de confidentialité",
            not_found: "La page demandée n’a pas été trouvée",
            tmp_inclusion_cards: "Streetco lance sa campagne des Cartes de l'Inclusion qui traverse les villes de France pour porter haut les couleurs de l'accessibilité.",
        },
    },
    url: {
        offer: "offre",
        privacy_policy: "politique-de-confidentialite",
        legal_notice: "mentions-legales",
        tmp_inclusion_cards: "les-cartes-de-l-inclusion",
    }
}