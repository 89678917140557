import { environment } from "src/environments/environment";
import { LOCALE_DYNAMIC_EN, LOCALE_DYNAMIC_FR } from "src/locale/dynamic/_index";

export class LocaleData {

    static meta(): any {
        switch (environment.locale) {
            case 'fr':
                return LOCALE_DYNAMIC_FR.meta;
            case 'en':
                return LOCALE_DYNAMIC_EN.meta;
            default:
                break;

        }
    }

    static pageTitle(): any {
        switch (environment.locale) {
            case 'fr':
                return LOCALE_DYNAMIC_FR.pages.title;
            case 'en':
                return LOCALE_DYNAMIC_EN.pages.title;
            default:
                break;
        }
    }

    static pageDescription(): any {
        switch (environment.locale) {
            case 'fr':
                return LOCALE_DYNAMIC_FR.pages.description;
            case 'en':
                return LOCALE_DYNAMIC_EN.pages.description;
            default:
                break;
        }
    }

    static pageUrl(): any {
        switch (environment.locale) {
            case 'fr':
                return LOCALE_DYNAMIC_FR.url;
            case 'en':
                return LOCALE_DYNAMIC_EN.url;
            default:
                break;

        }
    }

}